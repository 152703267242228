import React from 'react'
import PropTypes from 'prop-types'
import '../scss/koble-right-choice.scss'
import CTAButton from '../common/CTAButton'
import Word from './Word'
import { HTMLContent } from '../common/Content'

export default function KobleRightChoice(props) {
  const { video, title, description, ctaDetails } = props

  return (
    <React.Fragment>
      <div className="d-md-flex position-relative koble-right-choice">
        <div className="w-50 w-m-100 video-parent">
          <video
            src={video.publicURL}
            className="video-player"
            loop={true}
            autoPlay="autoPlay"
            id="vid"
            muted
          ></video>
        </div>
        <div className="w-50 w-m-100 d-md-flex align-items-center ">
          <div className="text-container">
            <div className="styled-text d-flex">
              {title.map((props, index) => (
                <Word key={index} {...props}></Word>
              ))}
            </div>
            <div className="desc mt-4 pb-2 fs-24">
              <HTMLContent content={description} />
            </div>
            <div className="note-container d-flex align-items-center mt-4">
              <CTAButton
                text={ctaDetails.text}
                link={ctaDetails.link}
                type={ctaDetails.type}
                bgColor={ctaDetails.bgColor}
                rightIcon={false}
                rightCircle={true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

KobleRightChoice.propTypes = {
  height: PropTypes.number,
  kobleRightChoice: PropTypes.object
}
