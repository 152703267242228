import React from 'react'
import PropTypes from 'prop-types'
import '../scss/privacy-policy-content.scss'
import { HTMLContent } from '../common/Content'

export default function HTMLContentContainer(props) {
  const { privacyPolicyContent, title } = props

  return (
    <React.Fragment>
      <div className="pp-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title d-flex justify-content-center">{title}</div>
            </div>
            <div className="col-12">
              <HTMLContent content={privacyPolicyContent} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

HTMLContentContainer.propTypes = {
  title: PropTypes.string,
  privacyPolicyContent: PropTypes.object
}
